<script setup lang="ts">
import { useMainStore } from '../store/index.ts';

const store = useMainStore();

const reducedColours = ref(false);
const reducedItems = ref(false);

watch(reducedColours, () => {
    document.body.classList.toggle('reduced-colours', reducedColours.value);
    localStorage.setItem('reducedColours', reducedColours.value.toString());
});
watch(reducedItems, () => {
    localStorage.setItem('reducedItems', reducedItems.value.toString());
    store.setReducedItems(reducedItems.value);
});
onMounted(() => {
    reducedColours.value = localStorage.getItem('reducedColours') === 'true';
    reducedItems.value = localStorage.getItem('reducedItems') === 'true';
    store.setReducedItems(reducedItems.value);
});
</script>

<template>
    <ul class="list-inline">
        <li class="list-inline-item">
            <T>mode.accessibility</T><T>quotation.colon</T>
        </li>
        <li class="list-inline-item">
            <label class="form-check form-switch d-inline-block">
                <input v-model="reducedColours" class="form-check-input" type="checkbox" role="switch">
                <T>mode.reducedColours</T>
            </label>
        </li>
        <li class="list-inline-item">
            <label class="form-check form-switch d-inline-block">
                <input v-model="reducedItems" class="form-check-input" type="checkbox" role="switch">
                <T>mode.reducedItems</T>
            </label>
        </li>
    </ul>
</template>
